import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

const Header = (props) => {
    const [myPath, setMyPath] = useState('my_path', []);

    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        setMyPath(location.pathname.split('/'));
    }, [location.pathname]);

    return (
        <header>
            <div className='header-top-content'>
                <div style={{ width: 100 }}></div>
                <div>
                    <h1 style={{ width: '100%', textAlign: 'center' }}>
                        {
                            myPath.includes('beranda') ?
                                t('title_app')
                                : myPath.includes('pengajuan_izin') ?
                                    t('pengajuan_izin.my_app_title')
                                    : myPath.includes('akun-manajemen') ?
                                        t('account.my_app_title')
                                        : myPath.includes('notification') ?
                                            t('notification.my_app_title')
                                            : myPath.includes('riwayat-presensi') ?
                                                t('histori_absen.my_app_title')
                                                : myPath.includes('rekap') ?
                                                    t('rekap.my_app_title')
                                                    : t('histori_absen.my_app_title')
                        }
                    </h1>
                </div>
                <div className="right">
                    <div className="button-notif" onClick={() => history.push('/notification')}>
                        <FontAwesomeIcon icon={faBell} className="userIcon" size='2x' />
                        {props?.data?.dashboard.new_notification ? <span className="count">{props?.data?.dashboard.new_notification}</span> : <></>}
                    </div>
                </div>
            </div>
            {
                props?.userData?.level_int === 2 ?
                    myPath.includes('beranda') ?
                        <>
                            <div className="text-center links">
                                <NavLink
                                    to={{
                                        pathname: '/beranda/kantor',
                                        state: {
                                            id: 3,
                                            slug: 'kantor',
                                            title: t('title_kantor')
                                        }
                                    }}
                                    className="link"
                                    activeClassName="active"
                                    exact
                                >
                                    {t('title_kantor')}
                                </NavLink>
                                <NavLink to={{
                                    pathname: '/beranda/wfh',
                                    state: {
                                        id: 6,
                                        slug: 'wfh',
                                        title: t('title_wfh')
                                    }
                                }} className="link" activeClassName="active">
                                    {t('title_wfh')}
                                </NavLink>
                                <NavLink to={{
                                    pathname: '/beranda/dinas-luar',
                                    state: {
                                        id: 4,
                                        slug: 'dinas-luar',
                                        title: t('title_dl')
                                    }
                                }} className="link" activeClassName="active">
                                    {t('title_dl')}
                                </NavLink>
                            </div>
                        </>
                        :
                        <>
                        </>
                    : props?.userData?.level_int === 1 ? myPath.includes('akun-manajemen') ?
                        <>
                            <div className="links">
                                <NavLink to="/akun-manajemen" className="link" activeClassName="active" exact>
                                    {t('account.my_nav1')}
                                </NavLink>
                                <NavLink to="/akun-manajemen/staff-manajemen" className="link" activeClassName="active">
                                    {t('account.my_nav2')}
                                </NavLink>
                                <NavLink to="/akun-manajemen/divisi-manajemen" className="link" activeClassName="active">
                                    {t('account.my_nav3')}
                                </NavLink>
                            </div>
                        </>
                        :
                        <></>
                        :
                        <></>
            }
        </header>
    );
};

export default Header;
