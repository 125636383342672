import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect, Link } from 'react-router-dom';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { slide as Menu } from 'react-burger-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmbulance, faBook, faFile, faFileAlt, faHistory, faHome, faSignOutAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';

//IMPORT PAGES
import LandingPageView from '../pages/LandingPage';

import Header from '../components/Header';
import LoginView from '../components/Login';

import KantorView from '../components/Kantor';

import JurnalView from '../components/Jurnal';
import JadwalWFHView from '../components/JadwalWFH';
import PengajuanIzinView from '../components/PengajuanIzin';

import RiwayatAbsenView from '../components/RiwayatAbsen';
import RiwayatIzinView from '../components/RiwayatIzin';
import RekapStaffView from '../pages/staf/rekap';
import RekapSSOStaffView from '../pages/staf/rekap/sso_rekap';

//PAGES ADMIN
import BerandaAdminView from '../pages/admin';
import BerandaPimpinanView from '../pages/pimpinan';
import ListStaffView from '../pages/pimpinan/manajemen_akun/ListStaff';
import AddStaffView from '../pages/pimpinan/manajemen_akun/AddStaff';
import EditStaffView from '../pages/pimpinan/manajemen_akun/EditStaff';
import ProfileAdminView from '../pages/pimpinan/manajemen_akun/Profile';
import DivisiAdminView from '../pages/pimpinan/manajemen_akun/Divisi';
import NotificationPage from '../pages/pimpinan/NotificationPage';
import RiwayatPresensiPage from '../pages/pimpinan/riwayat_presensi/index';
import RiwayatIzinPage from '../pages/pimpinan/riwayat_izin/index';
import RekapPage from '../pages/pimpinan/rekap/index';

import NotFound from '../components/NotFound';

//ADDITONAL
import useLocalStorage from '../hooks/useLocalStorage';
import PresenceContext from '../context/PresenceContext';
import 'moment/locale/id';
import { firebaseCloudMessaging } from '../utils/webPush';
import userDefault from '../img/user-default.png';
import apiService from '../services/api.service';
import useFCM from '../hooks/useFCM';

const AppRouter = (props) => {
  const [remember, setRemember] = useLocalStorage('remember_me', []);
  const [menuOpen, setMenuOpen] = useState(false);
  const [infoAbsent, setInfoAbsent] = useState(null);
  const [_payload] = useFCM();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const _fireBase = async () => {
      await firebaseCloudMessaging.init();
    }
    _fireBase();
  }, [props]);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  const handleStateChange = e => {
    setMenuOpen(e.isOpen);
  }

  const toggleMenu = e => {
    setMenuOpen(!menuOpen);
  }

  const tesBtn = e => {
    setMenuOpen(!menuOpen);
    setRemember(null);
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  const changeLanguage = (lng) => {
    moment.locale(lng);
    setMenuOpen(!menuOpen);
    i18n.changeLanguage(lng);
  };

  const level = (view) => {
    let localData = {
      level_int: remember?.level_int
    };
    return _.isObject(view) ?
      view.hasOwnProperty(localData.level_int) ? (
        view[localData.level_int]
      ) : NotFound : NotFound;
  }

  useEffect(() => {
    async function fetchData() {
      if (_.size(remember) > 0 && remember?.level_int === 1) {
        await apiService.getPimpinanDataHome()
          .then((res) => {
            setInfoAbsent(res?.data);
          });
      }
      if (_.size(remember) > 0 && remember?.level_int === 2) {
        await apiService.getInfoStaf({ "check_in": moment() })
          .then((res) => {
            setInfoAbsent(res?.data);
          });
      }
    }
    fetchData();
  }, [_payload, remember]);

  return (
    <BrowserRouter>
      <Switch>
        {_.size(remember) > 0 ?
          <React.Fragment>
            <div id="outer-container">
              <Menu
                isOpen={menuOpen}
                pageWrapId={"page-wrap"}
                outerContainerId={"outer-container"}
                onStateChange={handleStateChange}
                className={"my-menu"}
              >
                <div className="my-menu-header">
                  <div>
                    <img src={remember?.face_user?.name_image ?? userDefault} alt="Avatar" style={{ borderRadius: '50%', width: 150, maxHeight: 150 }} />
                  </div>
                  <br />
                  <div><h3>{remember.data && remember.data.nama}</h3></div>
                  <div><h6>{remember.data && remember.data.nama_jabatan}</h6></div>
                  <div><h6>{remember.data && remember.data.email}</h6></div>
                </div>
                <hr />
                {
                  remember?.level_int === 2 ?
                    <>
                      <Link id="beranda" className="menu-item" to="/beranda" onClick={toggleMenu}>
                        <div className="my-item-icon"><FontAwesomeIcon icon={faHome} /></div>
                        <div>{t('menu.my_home')}</div>
                      </Link>
                      <Link id="pengajuan_izin" className="menu-item" to="/pengajuan_izin" onClick={toggleMenu}>
                        <div className="my-item-icon"><FontAwesomeIcon icon={faAmbulance} /></div>
                        <div>{t('menu.my_izin')}</div>
                      </Link>
                      <Link id="pengajuan_izin" className="menu-item" to="/riwayat_absen" onClick={toggleMenu}>
                        <div className="my-item-icon"><FontAwesomeIcon icon={faHistory} /></div>
                        <div>{t('menu.my_history_izin')}</div>
                      </Link>
                      <Link id="pengajuan_izin" className="menu-item" to="/rekap/absen" onClick={toggleMenu}>
                        <div className="my-item-icon"><FontAwesomeIcon icon={faBook} /></div>
                        <div>{t('menu.my_rekap')}</div>
                      </Link>
                      <Link id="logout" className="menu-item logout" to="/" onClick={tesBtn}>
                        <div className="my-item-icon"><FontAwesomeIcon icon={faSignOutAlt} /></div>
                        <div>{t('menu.my_logout')}</div>
                      </Link>
                    </>
                    : <></>
                }

                {remember?.level_int === 1 ?
                  <>
                    <Link id="beranda" className="menu-item" to="/beranda" onClick={toggleMenu}>
                      <div className="my-item-icon"><FontAwesomeIcon icon={faHome} /></div>
                      <div>{t('menu.my_home')}</div>
                    </Link>
                    <Link id="pengajuan_izin" className="menu-item" to="/riwayat-presensi" onClick={toggleMenu}>
                      <div className="my-item-icon"><FontAwesomeIcon icon={faHistory} /></div>
                      <div>{t('menu.my_history_izin')}</div>
                    </Link>
                    <Link id="pengajuan_izin" className="menu-item" to="/riwayat-izin" onClick={toggleMenu}>
                      <div className="my-item-icon"><FontAwesomeIcon icon={faAmbulance} /></div>
                      <div>{t('menu.my_izin')}</div>
                    </Link>
                    <Link id="akun_manajemen" className="menu-item" to="/akun-manajemen" onClick={toggleMenu}>
                      <div className="my-item-icon"><FontAwesomeIcon icon={faUsers} /></div>
                      <div>{t('menu.my_akun_manajemen')}</div>
                    </Link>
                    <Link id="akun_manajemen" className="menu-item" to="/rekap" onClick={toggleMenu}>
                      <div className="my-item-icon"><FontAwesomeIcon icon={faBook} /></div>
                      <div>{t('menu.my_rekap')}</div>
                    </Link>
                    <Link id="logout" className="menu-item logout" to="/" onClick={tesBtn}>
                      <div className="my-item-icon"><FontAwesomeIcon icon={faSignOutAlt} /></div>
                      <div>{t('menu.my_logout')}</div>
                    </Link>
                  </>
                  : <></>
                }

                {/* {remember?.level_int === 2 ?
                  <>
                    <Link id="beranda" className="menu-item" to="/beranda" onClick={toggleMenu}>
                      <div className="my-item-icon"><FontAwesomeIcon icon={faHome} /></div>
                      <div>{t('menu.my_home')}</div>
                    </Link>
                    <Link id="pengajuan_izin" className="menu-item disable-link-sidemenu" to="/" onClick={toggleMenu}>
                      <div className="my-item-icon"><FontAwesomeIcon icon={faHistory} /></div>
                      <div>{t('menu.my_history_izin')}<br />(Comming Soon)</div>
                    </Link>
                    <Link id="logout" className="menu-item logout" to="/" onClick={tesBtn}>
                      <div className="my-item-icon"><FontAwesomeIcon icon={faSignOutAlt} /></div>
                      <div>{t('menu.my_logout')}</div>
                    </Link>
                  </>
                  : <></>
                } */}
                <div>
                  <Button onClick={() => changeLanguage('en')} style={{ marginRight: 10 }}>En</Button>
                  <Button onClick={() => changeLanguage('id')}>ID</Button>
                </div>
              </Menu>
              <main id="page-wrap" className="main-content">
                <Header userData={remember} data={infoAbsent} />
                <PresenceContext.Provider value={{ remember, setRemember }}>
                  {
                    remember?.level_int === 2 ?
                      <>
                        <Route component={() => <Redirect to={{
                          pathname: '/beranda/kantor',
                          state: {
                            id: 3,
                            slug: 'kantor',
                            title: t('title_kantor')
                          }
                        }} />} path="/" exact />

                        <Route component={() => <Redirect to={{
                          pathname: '/beranda/kantor',
                          state: {
                            id: 3,
                            slug: 'kantor',
                            title: t('title_kantor')
                          }
                        }} />} path="/beranda" exact />
                        <Route component={KantorView} path="/beranda/:id" />

                        <Route component={JurnalView} path="/jurnal" exact />
                        <Route component={JadwalWFHView} path="/jadwal_wfh" exact />
                        <Route component={NotificationPage} path="/notification" exact />

                        <Route component={PengajuanIzinView} path="/pengajuan_izin" exact />

                        <Route component={() => <Redirect to="/riwayat-presensi" />} path="/riwayat_absen" exact />
                        <Route component={() => <Redirect to="/pengajuan_izin" />} path="/riwayat-izin" exact />
                        <Route component={RiwayatAbsenView} path="/riwayat-presensi" />
                        <Route component={RekapStaffView} path="/rekap/absen" />
                      </>
                      :
                      <>
                        <Route component={() => <Redirect to="/beranda" />} path="/" exact />

                        <Route render={() => level({ 1: <BerandaPimpinanView userData={infoAbsent} />, 2: BerandaAdminView })} path="/beranda" />
                        <Route component={NotificationPage} path="/notification" exact />
                        <Route component={ProfileAdminView} path="/akun-manajemen" exact />
                        <Route component={RiwayatPresensiPage} path="/riwayat-presensi" exact />
                        <Route component={RiwayatIzinPage} path="/riwayat-izin" exact />
                        <Route component={RekapPage} path="/rekap" exact />
                        <Route component={ListStaffView} path="/akun-manajemen/staff-manajemen" exact />
                        <Route component={DivisiAdminView} path="/akun-manajemen/divisi-manajemen" exact />
                        <Route component={AddStaffView} path="/akun-manajemen/staff-manajemen/add" />
                        <Route component={EditStaffView} path="/akun-manajemen/staff-manajemen/edit/:id" />
                      </>
                  }

                  <Route component={() => <Redirect to="/beranda" />} path="/" exact />
                </PresenceContext.Provider>
              </main>
            </div>
          </React.Fragment>
          :
          <React.Fragment>
            <PresenceContext.Provider value={{ remember, setRemember }}>
              <Route exact component={LoginView} path="/login" />
              <Route exact component={LandingPageView} path="/" />
              <Route exact component={RekapSSOStaffView} path="/rekap" />
            </PresenceContext.Provider>
          </React.Fragment>
        }
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
