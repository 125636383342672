import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import PresenceContext from '../context/PresenceContext';
import Swal from 'sweetalert2';
import AuthService from '../services/auth.service';
import { useEffect } from 'react';
import { firebaseCloudMessaging } from '../utils/webPush';

const LoginView = () => {
    const history = useHistory();
    const { setRemember } = useContext(PresenceContext);
    const [rmb, setRmb] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tokenFCM, setTokenFCM] = useState(null);

    useEffect(() => {
        const fetchToken = async () => {
            const tokenFCM = await firebaseCloudMessaging.tokenInlocalforage();
            setTokenFCM(tokenFCM);
        }
        fetchToken();
    }, [tokenFCM])

    const handleChange = e => {
        setPasswordShown(passwordShown ? false : true);
    }

    const sampleLogin = e => {
        e.preventDefault();
        setIsLoading(true);
        AuthService.login(e.target.email.value, e.target.password.value, tokenFCM)
        .then(response => {
            setIsLoading(false);
            setRemember({ remember: rmb, ...response });
            // history.replace('/');
            window.location.href = '/';
        }).catch(err => {
            setIsLoading(false);
            Swal.fire({
                title: 'Gagal!',
                text: err.response.data.message,
                icon: 'error'
            }).then(x => {

            });
        });
    }

    return (
        <div>
            <div className="content-center">
                <br />
                <div className="login-card">
                    <Form onSubmit={sampleLogin}>
                        <h3>Presensi</h3>
                        <br />
                        <div className="form-group">
                            <label>Email</label>
                            <input name="email" type="email" className="form-control" placeholder="Enter email" autoComplete="off" defaultValue={process.env.REACT_APP_BASE_EMAIL} />
                        </div>
                        <br />
                        <div className="form-group">
                            <label>Password</label>
                            <input name="password" type={passwordShown ? "text" : "password"} className="form-control" autoComplete="current-password" defaultValue={process.env.REACT_APP_BASE_PASS} />
                        </div>

                        <br />
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check name="show_hide" type="checkbox" label="Lihat Password" style={{ paddingRight: 0 }} onChange={handleChange} />
                        </Form.Group>

                        <div className="form-group">
                            <Button type="submit" size="lg" variant="dark" disabled={isLoading}>
                                {
                                    isLoading ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                role="status"
                                                aria-hidden="true"
                                                style={{
                                                    width: 20,
                                                    height: 20
                                                }}
                                            />&nbsp;
                                        </>
                                    :
                                    <></>
                                }
                                Masuk
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default LoginView;
